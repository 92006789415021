import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  Badge,
  Button,
  Col,
  List,
  message,
  Popconfirm,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";

import VideosessionService from "../../utils/VideosessionService";
import LanguagesService from "../../utils/LanguagesService";
import {
  IAdditionalOption,
  ILanguage,
  IVideosession,
  SessionLog,
  TimeRange,
  VideosessionStatus,
} from "../../utils/interfaces";
import { stripeCountries } from "../../utils/stripeCountries";
import { Link } from "react-router-dom";
import {
  ClockCircleOutlined,
  DoubleRightOutlined,
  EditOutlined,
  PlusOutlined,
  CloseOutlined,
  ToolOutlined,
  CopyOutlined,
} from "@ant-design/icons";

import "./index.scss";
import ChangeStartTime from "../../components/VideosessionModals/ChangeStartTime";
import { getEventDescription } from "../../helpers/transform";
import InviteInterpreter from "../../components/VideosessionModals/InviteInterpreter";
import UpdateVideosessionValue from "../../components/VideosessionModals/UpdateVideosession";
import UpdateVideosessionCreator from "../../components/VideosessionModals/UpdateVideosessionCreator";
import UpdateVideosessionAdditionalFields from "../../components/VideosessionModals/UpdateVideosessionAdditionalFields";
import UpdateVideosessionDuration from "../../components/VideosessionModals/UpdateVideosessionDuration";

const { Title, Text } = Typography;

enum ModalTypes {
  UPDATE_START_TIME = "UPDATE_START_TIME",
  UPDATE_DESCRIPTION = "UPDATE_DESCRIPTION",
  UPDATE_NOTES = "UPDATE_NOTES",
  UPDATE_COST_CENTER = "UPDATE_COST_CENTER",
  UPDATE_TITLE = "UPDATE_TITLE",
  UPDATE_CREATOR = "UPDATE_CREATOR",
  UPDATE_ADDITIONAL_OPTIONS = "UPDATE_ADDITIONAL_OPTIONS",
  INVITE_INTERPRETER = "INVITE_INTERPRETER",
  UPDATE_DURATION = "UPDATE_DURATION",
}

const Videosession: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [videosession, setVideosession] = useState<IVideosession>();
  const [sessionLogs, setSessionLogs] = useState<SessionLog[]>([]);
  const [modal, setModal] = useState({
    type: "",
  });
  const [spokenLanguages, setSpokenLanguages] = useState<ILanguage[]>([]);
  const [signLanguages, setSignLanguages] = useState<ILanguage[]>([]);

  useEffect(() => {
    if (!id) return;

    const init = async () => {
      const fetchedVideosession = await VideosessionService.getVideosession(id);
      const spoken = await LanguagesService.getSpokenLanguages();
      const sign = await LanguagesService.getSignLanguages();

      setVideosession(fetchedVideosession);
      setSpokenLanguages(spoken);
      setSignLanguages(sign);

      try {
        const fetchedSessionLogs = await VideosessionService.getSessionLogs(id);
        const logsToSet = Object.keys(fetchedSessionLogs.events)
          .map((key) => ({
            name: key,
            data: fetchedSessionLogs.events[key],
          }))
          .sort((a: any, b: any) =>
            (a.data?.time
              ? new Date(a.data.time).getTime()
              : new Date(a.data).getTime()) >
              (b.data?.time
                ? new Date(b.data.time).getTime()
                : new Date(b.data).getTime())
              ? -1
              : 1
          );
        setSessionLogs(logsToSet);
      } catch { }
    };

    init();
  }, [id]);

  const getLanguageTitle = (id: number, spoken: boolean) => {
    return (spoken ? spokenLanguages : signLanguages).find(
      (lang) => lang.id === id
    )?.title_en;
  };

  const transformTimeRange = (timeRange: TimeRange): string => {
    if (timeRange === "m30") return "30 min";
    if (timeRange === "h1") return "1 hour";
    if (timeRange === "h2") return "2 hours";
    return "2+ hours";
  };

  const handleClose = () => {
    setModal({
      type: "",
    });
  };

  const handleError = (err: any) => {
    if (err.response?.data?.message) {
      message.error(err.response?.data?.message);
    } else {
      message.error("Something went wrong");
    }
  };

  const updateStartTime = async (
    date: string,
    setSubmitting: (isSubmitting: boolean) => void,
    clearState: () => void
  ) => {
    if (!videosession) return;
    setSubmitting(true);

    try {
      const updatedVideosession = await VideosessionService.changeStartTime(
        date,
        videosession?.id as number
      );

      setVideosession({
        ...videosession,
        startTime: updatedVideosession.startTime,
      });

      clearState();
      handleClose();
      message.success("Start time successfully updated!");
    } catch (err) {
      handleError(err);
    } finally {
      setSubmitting(false);
    }
  };
  const updateVideosessionValue = async (
    name: string,
    value: string,
    setSubmitting: (isSubmitting: boolean) => void,
    clearState: () => void
  ) => {
    if (!videosession) return;
    setSubmitting(true);

    try {
      const updatedVideosession =
        await VideosessionService.updateVideosessionField(
          name,
          value,
          videosession?.id as number
        );
      setVideosession({
        ...videosession,
        [name]: updatedVideosession[name as keyof IVideosession],
      });
      clearState();
      handleClose();
      message.success(
        `${name[0].toUpperCase() + name.substring(1)} successfully updated!`
      );
    } catch (err) {
      handleError(err);
    } finally {
      setSubmitting(false);
    }
  };
  const updateVideosessionCreator = async (
    creatorId: string,
    setSubmitting: (isSubmitting: boolean) => void,
    clearState: () => void
  ) => {
    if (!videosession) return;
    setSubmitting(true);

    try {
      const updatedVideosession =
        await VideosessionService.updateVideosessionField(
          "creatorId",
          creatorId,
          videosession?.id as number
        );

      setVideosession({
        ...videosession,
        creator: updatedVideosession.creator,
      });
      clearState();
      handleClose();
      message.success("Creator successfully updated!");
    } catch (err) {
      handleError(err);
    } finally {
      setSubmitting(false);
    }
  };
  const updateVideosessionAdditionaFields = async (
    additionalFields: IAdditionalOption[] | undefined,
    setSubmitting: (isSubmitting: boolean) => void,
    clearState: () => void
  ) => {
    if (!videosession) return;
    setSubmitting(true);

    try {
      const updatedVideosession =
        await VideosessionService.updateVideosessionField(
          "additionalFields",
          additionalFields,
          videosession?.id as number
        );

      setVideosession({
        ...videosession,
        additionalFields: updatedVideosession.additionalFields,
      });
      clearState();
      handleClose();
      message.success("Additional fields successfully updated!");
    } catch (err) {
      handleError(err);
    } finally {
      setSubmitting(false);
    }
  };

  const inviteInterpreter = async (
    interpreterId: number,
    setSubmitting: (isSubmitting: boolean) => void,
    clearState: () => void,
    setError: (msg: string) => void
  ) => {
    if (!videosession) return;
    setSubmitting(true);

    try {
      await VideosessionService.inviteInterpreter(
        videosession.id,
        interpreterId
      );

      const fetchedVideosession = await VideosessionService.getVideosession(
        videosession.id
      );
      setVideosession(fetchedVideosession);

      clearState();
      handleClose();
      message.success("Interpreter is successfully invited!");
    } catch (err: any) {
      if (err.response?.data?.message) {
        setError(err.response?.data?.message);
      } else {
        setError("Something went wrong");
      }
    } finally {
      setSubmitting(false);
    }
  };

  const updateDuration = async (
    duration: number,
    setSubmitting: (isSubmitting: boolean) => void,
    clearState: () => void,
    setError: (msg: string) => void
  ) => {
    if (!videosession) return;
    setSubmitting(true);

    try {
      await VideosessionService.changeDuration(
        videosession.id,
        duration
      );
      const fetchedVideosession = await VideosessionService.getVideosession(
        videosession.id
      );
      setVideosession(fetchedVideosession);
      try {
        const fetchedSessionLogs = await VideosessionService.getSessionLogs(`${videosession.id}`);
        const logsToSet = Object.keys(fetchedSessionLogs.events)
          .map((key) => ({
            name: key,
            data: fetchedSessionLogs.events[key],
          }))
          .sort((a: any, b: any) =>
            (a.data?.time
              ? new Date(a.data.time).getTime()
              : new Date(a.data).getTime()) >
              (b.data?.time
                ? new Date(b.data.time).getTime()
                : new Date(b.data).getTime())
              ? -1
              : 1
          );
        setSessionLogs(logsToSet);
      } catch { }
      clearState();
      handleClose();
      message.success("Duration successfully updated!");
    } catch (err: any) {
      if (err.response?.data?.message) {
        setError(err.response?.data?.message);
      } else {
        setError("Something went wrong");
      }
    } finally {
      setSubmitting(false);
    }
  }

  const changeStatusToCancel = async () => {
    if (!videosession) return;

    await VideosessionService.changeStatusToCancel(
      videosession?.id as number
    );

    window.location.reload();
  }

  const payEarningDifference = async () => {
    if (!videosession) return;

    await VideosessionService.payEarningDifference(
      videosession?.id as number
    );

    window.location.reload();
  }

  if (!videosession) {
    return (
      <Space size="middle">
        <Spin size="large" />
      </Space>
    );
  }

  return (
    <div>
      {videosession && (
        <>
          <Title>
            Title: {videosession.title}
            {' '}<EditOutlined
              style={{ fontSize: 26 }}
              className="editBtn"
              onClick={() =>
                setModal({
                  type: ModalTypes.UPDATE_TITLE,
                })
              }
            />
            {' '}<CopyOutlined
              style={{ fontSize: 26 }}
              className="editBtn"
              onClick={() => {
                navigate(`/admin/videosessions/new?copySessionId=${id}`);
              }}
            />
          </Title>

          <Row>
            <Col span={12} className="columnView">
              <Space
                direction="vertical"
                style={{ alignItems: "start", textAlign: "left" }}
              >
                <Title level={3}>Videosession Info:</Title>
                <Text strong>
                  Start Time:{" "}
                  {new Date(videosession.startTime)
                    .toLocaleString()
                    .slice(0, -3)}{" "}
                  {videosession.status === "PAID" && (
                    <EditOutlined
                      style={{ fontSize: 20 }}
                      className="editBtn"
                      onClick={() =>
                        setModal({
                          type: ModalTypes.UPDATE_START_TIME,
                        })
                      }
                    />
                  )}
                </Text>
                <Text strong>
                  Specialization: {videosession.expertiseArea?.title_en}
                </Text>
                <Text strong>
                  Description: {videosession.description}{" "}
                  <EditOutlined
                    style={{ fontSize: 20 }}
                    className="editBtn"
                    onClick={() =>
                      setModal({
                        type: ModalTypes.UPDATE_DESCRIPTION,
                      })
                    }
                  />
                </Text>
                <Text strong>
                  Notes: {videosession.notes}{" "}
                  <EditOutlined
                    style={{ fontSize: 20 }}
                    className="editBtn"
                    onClick={() =>
                      setModal({
                        type: ModalTypes.UPDATE_NOTES,
                      })
                    }
                  />
                </Text>
                {
                  ["Medicine and health care", 'Psychiatry / psychotherapy'].includes(videosession.expertiseArea.title_en) && (
                    <Text strong>
                      Additional options:
                      {videosession?.additionalFields?.map((af) => (
                        <>
                          <i>{af.title.en}</i> - {af.value}.{" "}
                        </>
                      ))}
                      <EditOutlined
                        style={{ fontSize: 20 }}
                        className="editBtn"
                        onClick={() =>
                          setModal({
                            type: ModalTypes.UPDATE_ADDITIONAL_OPTIONS,
                          })
                        }
                      />
                    </Text>
                  )}
                <Text strong>Status: {videosession.status}
                  {[VideosessionStatus.SUCCEED, VideosessionStatus.TIME_OUT, VideosessionStatus.COMPANY_MISSED].includes(videosession.status) && videosession.history && <Popconfirm
                    title="Do you want to change status to canceled?"
                    onConfirm={changeStatusToCancel}
                  >
                    <CloseOutlined
                      style={{ fontSize: 20 }}
                      className="editBtn"
                    />
                  </Popconfirm>}
                </Text>
                <Text strong>
                  Cost Center: {videosession.costCenter || "-"}

                  <EditOutlined
                    style={{ fontSize: 20 }}
                    className="editBtn"
                    onClick={() =>
                      setModal({
                        type: ModalTypes.UPDATE_COST_CENTER,
                      })
                    }
                  />
                </Text>
                {videosession.department &&
                  <Text strong>
                    Department: {videosession.department.title_en} {videosession.department.costCenter ? `(cost center: ${videosession.department.costCenter})` : ""}
                  </Text>}
                <Text strong>
                  Translate From:{" "}
                  {getLanguageTitle(videosession.translateFrom, true)}
                </Text>
                <Text strong>
                  Translate To:{" "}
                  {getLanguageTitle(
                    videosession.translateTo || videosession.translateToSign,
                    !!videosession.translateTo
                  )}
                </Text>
                <Text strong>
                  Time range: {transformTimeRange(videosession.timeRange)}
                </Text>
                <Text strong>
                  Is free: {(!!videosession?.history?.allowForFree).toString()}
                </Text>
                {!!videosession?.history?.spendings && <>
                  <Text strong>
                    Price: CHF {(videosession.history.spendings / 100).toFixed(2)}
                  </Text>
                </>}
                {!!videosession?.history?.earnings && <>
                  <Text strong>
                    Earnings: CHF {(videosession.history.earnings / 100).toFixed(2)}
                  </Text>
                </>}
                {typeof videosession?.earningPaid === 'number' && <>
                  <Text strong>
                    Earnings paid: CHF {(videosession.earningPaid / 100).toFixed(2)}
                    {[VideosessionStatus.CANCELLED, VideosessionStatus.FAILED, VideosessionStatus.SUCCEED].includes(videosession.status) && videosession?.history?.earnings && videosession.earningPaid < videosession.history.earnings &&
                      <Popconfirm
                        title={`Do you want to change pay the difference CHF${((videosession.history.earnings - videosession.earningPaid) / 100).toFixed(2)}?`}
                        onConfirm={payEarningDifference}
                      >
                        <ToolOutlined
                          style={{ fontSize: 20 }}
                          className="editBtn"
                        />
                      </Popconfirm>
                    }
                  </Text>
                </>}

                {typeof videosession?.history?.spentMinutes === 'number' && <>
                  <Text strong>
                    Spent time: {videosession.history.spentMinutes} min
                    <EditOutlined
                      style={{ fontSize: 20 }}
                      className="editBtn"
                      onClick={() =>
                        setModal({
                          type: "UPDATE_DURATION",
                        })
                      }
                    />
                  </Text>
                </>}
              </Space>
            </Col>
            <Col span={12} className="columnView">
              <Space
                direction="vertical"
                style={{ alignItems: "start", textAlign: "left" }}
              >
                <Title level={3}>
                  Company:{" "}
                  <Link
                    className="detailsLink"
                    to={`/admin/companies/${videosession.creator.profileId}`}
                  >
                    <DoubleRightOutlined />
                  </Link>
                </Title>
                <Text strong>Name: {videosession.company.name}</Text>
                <Text strong>
                  Country:{" "}
                  {videosession.company.address.country &&
                    (stripeCountries as any)[
                    videosession.company.address.country.toUpperCase()
                    ]}
                </Text>
                <Text strong>City: {videosession.company.address.city}</Text>
                <Text strong>
                  Street: {videosession.company.address.street}
                </Text>
                <Text strong>
                  Postal Code: {videosession.company.address.postalCode}
                </Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginTop: 30 }}>
            <Col span={12} className="columnView">
              <Space
                direction="vertical"
                style={{ alignItems: "start", textAlign: "left" }}
              >
                <Title level={3}>
                  Creator:
                  <EditOutlined
                    style={{ fontSize: 22 }}
                    className="editBtn"
                    onClick={() =>
                      setModal({
                        type: ModalTypes.UPDATE_CREATOR,
                      })
                    }
                  />
                </Title>
                <Text strong>
                  Name:{" "}
                  {`${videosession.creator.firstName} ${videosession.creator.lastName}`}
                </Text>
                <Text strong>Email: {videosession.creator.email}</Text>
                <Text strong>Role: {videosession.creator.role}</Text>
              </Space>
            </Col>

            <Col span={12} className="columnView">
              <Space
                direction="vertical"
                style={{ alignItems: "start", textAlign: "left" }}
              >
                <Title level={3}>
                  Interpreter:{" "}
                  {videosession.interpreter && (
                    <Link
                      className="detailsLink"
                      to={`/admin/interpreters/${videosession.interpreter.id}`}
                    >
                      <DoubleRightOutlined />
                    </Link>
                  )}
                </Title>

                <Text strong>
                  Name:{" "}
                  {videosession.interpreter?.user
                    ? `${videosession.interpreter.user.firstName} ${videosession.interpreter.user.lastName}`
                    : "-"}
                </Text>
                <Text strong>
                  Email: {videosession.interpreter?.user.email || "-"}
                </Text>
                <Text strong>
                  Description: {videosession.interpreter?.about || "-"}
                </Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginTop: 30 }}>
            {videosession.initiator?.email &&
              <Col span={12} className="columnView">
                <Space
                  direction="vertical"
                  style={{ alignItems: "start", textAlign: "left" }}
                >
                  <Title level={3}>
                    Initiator:
                  </Title>
                  <Text strong>
                    Name:{" "}
                    {`${videosession.initiator.firstName} ${videosession.initiator.lastName}`}
                  </Text>
                  <Text strong>Email: {videosession.initiator.email}</Text>
                  <Text strong>Role: {videosession.initiator.role}</Text>
                </Space>
              </Col>
            }

            {videosession.participant &&
              <Col span={12} style={{ marginTop: 10 }} className="columnView">
                <Space
                  direction="vertical"
                  style={{ alignItems: "start", textAlign: "left" }}
                >
                  <Title level={3}>
                    Participant:
                  </Title>
                  <Text strong>
                    Name:{" "}
                    {`${videosession.participant.firstName} ${videosession.participant.lastName}`}
                  </Text>
                  <Text strong>Email: {videosession.participant.email}</Text>
                  <Text strong>Role: {videosession.participant.role}</Text>
                </Space>
              </Col>
            }
          </Row>
          <Row style={{ marginTop: 30 }}>
            {!!sessionLogs.length && (
              <Col span={12} className="columnView">
                <Space
                  direction="vertical"
                  style={{ alignItems: "start", textAlign: "left" }}
                >
                  <Title level={3}>History:</Title>
                  <List
                    className="history"
                    size="small"
                    bordered
                    dataSource={sessionLogs}
                    renderItem={(item) => (
                      <List.Item>{getEventDescription(item)}</List.Item>
                    )}
                  />
                </Space>
              </Col>
            )}{" "}
            <Col span={12} className="columnView">
              <Space
                direction="vertical"
                style={{ alignItems: "start", textAlign: "left" }}
              >
                <Title level={3}>Invited:</Title>
                <Text>
                  {videosession.invited?.map((item, i) =>
                    videosession.notInvited?.find(
                      (int) => int.id === item.id
                    ) ? (
                      <>
                        {i > 0 && ", "}
                        <Badge
                          count={
                            <ClockCircleOutlined style={{ color: "#f5222d" }} />
                          }
                        >
                          <Link to={`/admin/interpreters/${item.id}`}>
                            {item.user.firstName} {item.user.lastName}
                          </Link>
                        </Badge>
                      </>
                    ) : (
                      <>
                        {i > 0 && ", "}
                        <Link to={`/admin/interpreters/${item.id}`}>
                          {item.user.firstName} {item.user.lastName}
                        </Link>
                      </>
                    )
                  )}
                </Text>
                {[
                  VideosessionStatus.OPEN,
                  VideosessionStatus.NOTIFIED,
                  VideosessionStatus.REINVITED,
                ].includes(videosession.status) && (
                    <Button
                      icon={<PlusOutlined />}
                      size="small"
                      onClick={() =>
                        setModal({ type: ModalTypes.INVITE_INTERPRETER })
                      }
                    >
                      Invite interpreter
                    </Button>
                  )}
              </Space>
            </Col>
            {!!videosession.declined?.length && (
              <Col span={12} className="columnView">
                <Space
                  direction="vertical"
                  style={{ alignItems: "start", textAlign: "left" }}
                >
                  <Title level={3}>Declined:</Title>
                  <Text>
                    {videosession.declined.map((item, i) => (
                      <>
                        {i > 0 && ", "}
                        <Link to={`/admin/interpreters/${item.id}`}>
                          {item.user.firstName} {item.user.lastName}
                        </Link>
                      </>
                    ))}
                  </Text>
                </Space>
              </Col>
            )}
          </Row>
        </>
      )}

      <ChangeStartTime
        show={modal.type === ModalTypes.UPDATE_START_TIME}
        defaultDate={videosession.startTime}
        handleClose={handleClose}
        confirm={updateStartTime}
      />

      <UpdateVideosessionValue
        show={modal.type === ModalTypes.UPDATE_DESCRIPTION}
        value={videosession.description}
        name="description"
        handleClose={handleClose}
        confirm={updateVideosessionValue}
        textarea
      />

      <UpdateVideosessionValue
        show={modal.type === ModalTypes.UPDATE_NOTES}
        value={videosession.notes}
        name="notes"
        handleClose={handleClose}
        confirm={updateVideosessionValue}
        textarea
      />

      <UpdateVideosessionValue
        show={modal.type === ModalTypes.UPDATE_COST_CENTER}
        value={videosession.costCenter}
        name="costCenter"
        handleClose={handleClose}
        confirm={updateVideosessionValue}
      />

      <UpdateVideosessionValue
        show={modal.type === ModalTypes.UPDATE_TITLE}
        value={videosession.title}
        name="title"
        handleClose={handleClose}
        confirm={updateVideosessionValue}
      />

      <UpdateVideosessionCreator
        show={modal.type === ModalTypes.UPDATE_CREATOR}
        companyId={videosession.company.id}
        handleClose={handleClose}
        confirm={updateVideosessionCreator}
      />

      <UpdateVideosessionAdditionalFields
        show={modal.type === ModalTypes.UPDATE_ADDITIONAL_OPTIONS}
        currentAdditionalFields={videosession.additionalFields}
        medicineExpertiseId={videosession.expertiseArea.id}
        handleClose={handleClose}
        confirm={updateVideosessionAdditionaFields}
      />

      <InviteInterpreter
        show={modal.type === ModalTypes.INVITE_INTERPRETER}
        handleClose={handleClose}
        confirm={inviteInterpreter}
      />

      <UpdateVideosessionDuration
        show={modal.type === ModalTypes.UPDATE_DURATION}
        handleClose={handleClose}
        confirm={updateDuration}
        currentDuration={videosession.history?.spentMinutes || 0}
      />
    </div>
  );
};

export default Videosession;
