import React, { useRef } from "react";
import { Button, Modal } from "antd";
import { ErrorMessage, Formik, FormikProps } from "formik";
import { Form, InputNumber } from "formik-antd";
import Text from "antd/lib/typography/Text";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
    duration: Yup.number()
        .required("Duration is required.")
        .min(1, "Duration must be at least 1 minute.")
        .max(1000, "Duration must be less than 1000 minutes."),
});

type PropTypes = {
    show: boolean;
    currentDuration: number;
    handleClose: () => void;
    confirm: (
        duration: number,
        setSubmitting: (isSubmitting: boolean) => void,
        clearState: () => void,
        setError: (error: string) => void
    ) => void;
};

const UpdateVideosessionDuration: React.FC<PropTypes> = ({
    show,
    currentDuration,
    handleClose,
    confirm,
}) => {
    const formikRef = useRef<FormikProps<{ duration: number }>>(null);

    const handleConfirm = (
        setSubmitting: (isSubmitting: boolean) => void,
        resetForm: () => void,
        setError: (error: string) => void,
        duration: number
    ) => {
        if (!duration) return;
        confirm(duration, setSubmitting, resetForm, setError);
    };

    return (
        <Modal
            title="Update Video Session Duration"
            visible={show}
            onCancel={() => {
                handleClose();
                formikRef.current?.resetForm();
            }}
            footer={null}
        >
            <Formik
                innerRef={formikRef}
                initialValues={{ duration: currentDuration }}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={(values, { setSubmitting, resetForm, setFieldError }) => {
                    handleConfirm(
                        setSubmitting,
                        resetForm,
                        (msg: string) => setFieldError("duration", msg),
                        values.duration
                    );
                }}
            >
                {({ isSubmitting, submitForm }) => (
                    <Form>
                        <div className="durationGroup">
                            <Text strong>Duration (minutes):</Text>
                            <InputNumber name="duration" min={1} max={1000} />
                            <ErrorMessage name="duration" component="p" className="error" />
                        </div>
                        <Button
                            className="saveBtn"
                            onClick={submitForm}
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            type="primary"
                        >
                            Update Duration
                        </Button>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default UpdateVideosessionDuration;
