import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { IAdditionalOption, IVideosession, LogData, VideosessionStatus } from '../utils/interfaces';
import VideosessionService from '../utils/VideosessionService';

const customNumericId = (id: number, length: number) => {
    const stringId = id.toString();
    if (stringId.length < length) {
        return `${'0'.repeat(length - stringId.length)}${stringId}`;
    }
    return stringId;
};
function formatSwissTime(date: Date) {
    const options = {
        day: 'numeric',    // Use 'numeric' for day, month, and year
        month: 'numeric',
        year: 'numeric',
        hour: '2-digit',   // Use '2-digit' for hour and minute
        minute: '2-digit',
        timeZone: 'Europe/Zurich', // Swiss timezone (CET/CEST)
        hour12: false,     // 24-hour format
    };

    return date.toLocaleString('de-CH', options as any);
}

const formatCurrencyNumber = (value: number) => {
    // Ensure the value is saved as a numeric value with 2 decimal places
    return Number((value / 100).toFixed(2)); // Converts to CHF by dividing by 100
};

function formatSwissDateManual(date: Date) {
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();

    return `${day}.${month}.${year}`;
}

const timeRangeMapping: Record<string, string> = {
    m30: 'bis zu 30 min',
    h1: '30–60 min',
    h2: '1–2 Stunden',
    more: 'mehr als 2 Stunden',
};

const convertToExcelData = async (
    sessions: IVideosession[],
    getLanguageTitle: (id: number, spoken: boolean) => string | undefined
): Promise<any[][]> => {
    const headers = [
        'Einsatz Datum', 'Startzeit', 'Dauer in Minuten', 'Gebuchte Dauer', 'Zeitpunkt der Stornierung',
        'Grund der Stornierung', 'Titel', 'Kosten inkl. MWST 8.1%', 'Abteilung',
        'Abeilung/Kostenstelle', 'Kostenstelle', 'amb.', 'stat.', 'NF',
        'Gebucht von', 'Gesprächsleitung', 'Fall-Nr.', 'Geburtsdatum Patient',
        'Patienten-Initialen.', 'Patienten-ID', 'Sprache', 'Name', 'Bemerkungen', 'Auftrag Nr.', 'Datum der Buchung'
    ];

    const rows = await Promise.all(sessions.map(async (session) => {
        const patientType = session.additionalFields?.find(
            (a: IAdditionalOption) => a.name === 'patientType')?.value || '';
        const caseNumber = session.additionalFields?.find(
            (a: IAdditionalOption) => a.name === 'caseNumber')?.value;
        const patientBirth = session.additionalFields?.find(
            (a: IAdditionalOption) => a.name === 'patientBirth')?.value || '';
        const patientName = session.additionalFields?.find(
            (a: IAdditionalOption) => a.name === 'patientName')?.value || '';
        const patientId = session.additionalFields?.find(
            (a: IAdditionalOption) => a.name === 'patientId')?.value || '';
        let cancelationLog;
        if (session.status === VideosessionStatus.CANCELLED) {
            try {
                const log = (await VideosessionService.getSessionLogs(`${session.id}`).catch())?.events?.cancelledByCompany;
                cancelationLog = log;
            } catch {
            }
        }
        return [
            formatSwissDateManual(new Date(session.startTime)), // Start date
            new Date(session.startTime).toLocaleTimeString('de-DE'),  // Start time in CET
            session.history?.spentMinutes || 0,  // Duration in minutes
            timeRangeMapping[session.timeRange] || 'Unbekannt', // Booked duration
            (cancelationLog as LogData)?.time ? formatSwissTime(new Date((cancelationLog as LogData)?.time)) : '',  // Time of cancellation
            (cancelationLog as LogData)?.reason || '',  // Reason of cancellation
            session.title || '',  // Title
            session?.history?.spendings && !session.history?.allowForFree ? formatCurrencyNumber(session.history.spendings) : 0,  // Costs incl. VAT
            session.department?.title_de || '',  // Department
            session.department?.costCenter || '',  // Cost center/department
            !session.department?.costCenter ? session.costCenter : '',  // Cost center
            ['outpatient', 'ambulant', 'ambulatoire', 'ambulante'].includes(patientType),  // Outpatient
            ['inpatient', 'stationär', 'hospitalier', 'ricovero'].includes(patientType),  // Inpatient
            !patientType,  // No data for patient type
            session.creator.email || '',  // Creator
            session.participant ? session.participant.email : '',  // Participant
            caseNumber ? `${caseNumber}` : '',  // Case number
            patientBirth ? `${patientBirth}` : '',  // Birthdate
            patientName ? `${patientName}` : '',  // Patient initials
            patientId ? `${patientId}` : '',  // Patient ID
            getLanguageTitle(
                session.translateTo || session.translateToSign,
                !!session.translateTo
            ) || '',  // Language
            session.interpreter ? `${session.interpreter.firstName} ${session.interpreter.lastName}` : '',  // Interpreter name
            session.notes ? `${session.notes}` : '',  // Notes
            `ID${new Date(session.startTime).getFullYear()}${customNumericId(session.id, 5)}`,  // Session ID
            `${formatSwissDateManual(new Date(session.createdAt))}, ${new Date(session.startTime).toLocaleTimeString('de-DE')}` // Booking date
        ];
    }));
    return [headers, ...rows];
};

export const downloadSessionsExcel = async (
    sessions: IVideosession[],
    getLanguageTitle: (id: number, spoken: boolean) => string | undefined,
    title?: string
): Promise<void> => {
    const data = await convertToExcelData(sessions.sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime()), getLanguageTitle);
    const worksheet = XLSX.utils.aoa_to_sheet(data); // Converts data array to Excel sheet
    const workbook = XLSX.utils.book_new(); // Create a new workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sessions'); // Append the sheet to the workbook

    const xlsData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' }); // Generate Excel file

    const blob = new Blob([xlsData], { type: 'application/octet-stream' });
    saveAs(blob, `sessions${title ? ` (${title})` : ''}.xlsx`); // Download the file as .xlsx
};
