import {
  LogData,
  LogDataUser,
  SessionLog,
  SessionLogData,
  TimeRange,
} from "../utils/interfaces";

export const transformPrice = (amount: number): string => {
  const amountString = (+amount / 100).toString().split(".")[0];
  const lastTwo = amount?.toString().slice(-2);
  if (amountString.length > 3) {
    const stringArray = amountString
      .split("")
      .reverse()
      .join("")
      .match(/.{1,3}/g);
    return `${(stringArray as string[])
      .join(" ")
      .split("")
      .reverse()
      .join("")}${+lastTwo.length === 2 ? `.${lastTwo}` : ""}`;
  }
  return `${amountString}${lastTwo?.length === 2 ? `.${lastTwo}` : ""}`;
};

export const transformPriceFormatter = (amount?: number): string => {
  if (!amount) return "0.00";
  const amountString = (+amount / 100).toString().split(".")[0];
  const lastTwo = amount?.toString().slice(-2);
  if (amountString.length > 3) {
    const stringArray = amountString
      .split("")
      .reverse()
      .join("")
      .match(/.{1,3}/g);
    return `${(stringArray as string[])
      .join(" ")
      .split("")
      .reverse()
      .join("")}${+lastTwo.length === 2 ? `.${lastTwo}` : ""}`;
  }
  return `${amountString}${lastTwo?.length === 2 ? `.${lastTwo}` : ""}`;
};

const EVENT_CODES = [
  "created",
  "cancelledByCompany",
  "cancelledByInterpreter",
  "changedTime",
  "changedTimeRange",
  "changedTimeAdmin",
  "acceptedSession",
  "acceptedChanges",
  "rejectedChanges",
  "joined",
  "started",
  "ended",
  "changedToCancellationAdmin",
  "earningDiffPaidAdmin",
  "changedDurationAdmin",
];


const transformTimeRange = (timeRange: TimeRange): string => {
  if (timeRange === "m30") return "30 min";
  if (timeRange === "h1") return "1 hour";
  if (timeRange === "h2") return "2 hours";
  return "2+ hours";
};

const eventDescriptionString = (name: string, data: SessionLogData) => {
  if (name === "created")
    return `Created at ${new Date(data as Date).toLocaleString()}`;
  if (name === "cancelledByCompany")
    return `Cancelled by company at ${new Date(
      (data as LogData).time || data
    ).toLocaleString()}${(data as LogData)?.reason ? ". Reason: " + (data as LogData)?.reason : ""
      }`;
  if (name === "cancelledByInterpreter")
    return `Cancelled by interpreter ${(data as LogData).interpreter
      } at ${new Date((data as LogData).time).toLocaleString()}${(data as LogData)?.reason ? ". Reason: " + (data as LogData)?.reason : ""
      }`;
  if (name === "changedTime" && typeof data === 'object')
    return `Changed time at ${new Date(
      (data as LogData).time
    ).toLocaleString()} by company${(data as LogData).user ? `(${(data as LogData).user})` : ''} ${(data as LogData).reason}`;
  if (name === "changedTime")
    return `Changed time at ${new Date(
      data as Date
    ).toLocaleString()} by company`;
  if (name === "changedTimeRange")
    return `Changed time range to ${transformTimeRange((data as LogData).reason as TimeRange)} at ${new Date(
      (data as LogData).time
    ).toLocaleString()} by company${(data as LogData).user ? `(${(data as LogData).user})` : ''}`;
  if (name === "changedTimeAdmin" && typeof data === 'object')
    return `Changed time at ${new Date(
      (data as LogData).time
    ).toLocaleString()} by dolmX admin ${(data as LogData).reason}`;
  if (name === "changedTimeAdmin")
    return `Changed time at ${new Date(
      data as Date
    ).toLocaleString()} by dolmX admin`;
  if (name === "acceptedSession")
    return `Accepted by interpreter ${(data as LogData).interpreter
      } at ${new Date((data as LogData).time).toLocaleString()}`;
  if (name === "acceptedChanges")
    return `Changes accepted by interpreter ${(data as LogData).interpreter
      } at ${new Date((data as LogData).time).toLocaleString()}`;
  if (name === "rejectedChanges")
    return `Changes rejected by interpreter ${(data as LogData).interpreter
      } at ${new Date((data as LogData).time).toLocaleString()}`;
  if (name === "joined")
    return `${firstLetterUppercase(
      (data as LogDataUser).user
    )} joined at ${new Date((data as LogData).time).toLocaleString()}`;
  if (name === "started")
    return `Started at ${new Date(data as Date).toLocaleString()}`;
  if (name === "ended")
    return `Ended at ${new Date(data as Date).toLocaleString()}`;
  if (name === "changedToCancellationAdmin")
    return `Changed to cancellation by admin at ${new Date((data as LogData).time).toLocaleString()}${(data as LogData)?.user ? ". Admin email: " + (data as LogData)?.user : ""
      }`;
  if (name === "earningDiffPaidAdmin")
    return `Earning diff${(data as LogData)?.reason ? `(${(data as LogData)?.reason})` : ""
      } paid at ${new Date((data as LogData).time).toLocaleString()}${(data as LogData)?.user ? ". Admin email: " + (data as LogData)?.user : ""
      }`;
  if (name === "changedDurationAdmin")
    return `Admin ${(data as LogData)?.user ? `(${(data as LogData)?.user})` : ""
      } has changed time from ${(data as LogData)?.reason}min at ${new Date((data as LogData).time).toLocaleString()}`;
  return `${name} ${data}`;
};

export const getEventDescription = (event: SessionLog) => {
  const { name, data } = event;
  let code = name;
  while (!EVENT_CODES.includes(code)) {
    code = code.slice(0, -1);
  }
  return eventDescriptionString(code, data);
};

export const firstLetterUppercase = (str: string) =>
  str[0].toUpperCase() + str.slice(1);

export const truncate = (str: string, n: number) => {
  return str.length > n ? str.slice(0, n - 1) + "..." : str;
};
