import {
  Button,
  Col,
  Row,
  Space,
  Typography,
  message,
  Select as AntSelect,
  Checkbox as AntCheckbox,
  Modal,
  Input as AntInput,
} from "antd";
import { ErrorMessage, FieldArray, Formik } from "formik";
import { Form, Input, DatePicker, Checkbox, Select } from "formik-antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import * as Yup from "yup";

import {
  ICompany,
  ICreateVideosessionFormData,
  ICreateVideosessionFormDataExtended,
  IExpertiseArea,
  ILanguage,
  ISessionInterpreter,
  IUser,
} from "../../utils/interfaces";
import LanguagesService from "../../utils/LanguagesService";
import ExpertiseService from "../../utils/ExpertiseService";

import "./index.scss";
import additionalFields from "../../utils/additionalFields";
import CompanyService from "../../utils/CompanyService";
import InterpreterService from "../../utils/InterpreterService";
import dialectCountries from "../../utils/dialect-countries";
import VideosessionService from "../../utils/VideosessionService";
import AdminService from "../../utils/AdminService";
import AdditionalField from "../../components/AdditionalField";
import { useNavigate, useSearchParams } from "react-router-dom";

const { Title, Text } = Typography;

const initialAdditionalFields = additionalFields.map((f) => ({
  name: f.name,
  required: f.required,
  title: f.title,
}));

const initialV: ICreateVideosessionFormData = {
  title: "",
  description: "",
  type: "spoken" as "sign" | "spoken",
  startTime: new Date(Date.now() + 60 * 60 * 1000),
  timeRange: "m30",
  gender: "none" as "male" | "female" | "none",
  workWithPreviousInterpreters: false,
  translateFrom: "en",
  expertiseArea: 1,
  additionalFields: initialAdditionalFields,
  isFree: false,
};

const NewSession: React.FC = () => {
  const [searchParams] = useSearchParams();
  const copySessionId = searchParams.get("copySessionId");
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState<ICreateVideosessionFormData>(initialV);
  const [additionalFieldsExpertises, setAdditionalFieldsExpertises] = useState<number[]>([]);
  const [spokenLanguages, setSpokenLanguages] = useState<ILanguage[]>([]);
  const [signLanguages, setSignLanguages] = useState<ILanguage[]>([]);
  const [expertiseAreas, setExpertiseAreas] = useState<IExpertiseArea[]>([]);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [creators, setCreators] = useState<IUser[]>([]);
  const [availableInterpreters, setAvailableInterpreters] = useState<
    ISessionInterpreter[]
  >([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<
    number | undefined
  >();
  const [inviteInterpreters, setInviteInterpreters] = useState(false);
  const [selectedInterpreters, setSelectedInterpreters] = useState<number[]>(
    []
  );
  const [allowToSearchNew, setAllowToSearchNew] = useState(false);
  const [valuesToSubmit, setValuesToSubmit] =
    useState<ICreateVideosessionFormDataExtended>();
  const [modalVisible, setModalVisible] = useState(false);
  const [secret, setSecret] = useState<string>();
  const [code, setCode] = useState<string>();

  const sessionSchema = Yup.object().shape({
    title: Yup.string().trim().required("Title is required"),
    costCenter: Yup.string().trim(),
    description: Yup.string().trim(),
    expertiseArea: Yup.number().required("Expertise area is required"),
    companyId: Yup.number().required("Company is required"),
    userId: Yup.number().required("Creator is required"),
    startTime: Yup.date()
      .required("Start time is required")
      .test("start-time", "Start time should be later", function (value, ref) {
        if (value) {
          const minStart = new Date(new Date().getTime() + 60 * 1000);
          const currentStart = new Date(value);
          if (currentStart < minStart) {
            return false;
          }
        }
        return true;
      }),
    translateFrom: Yup.string().trim().required("Target language is required"),
    translateTo: Yup.string()
      .trim()
      .when("type", {
        is: (val: string) => val === "spoken",
        then: Yup.string().required("Source language is required"),
      })
      .when("translateFrom", {
        is: (val: string) => !!(val && val.length > 0),
        then: Yup.string().notOneOf(
          [Yup.ref("translateFrom")],
          "Target and source languages shouldn't match"
        ),
      }),
    dialect: Yup.string().trim(),
    translateToSign: Yup.string()
      .trim()
      .when("type", {
        is: (val: string) => val === "sign",
        then: Yup.string().required("Source language is required"),
      }),
    additionalFields: Yup.array()
      .when("expertiseArea", {
        is: (val: number) => {
          return additionalFieldsExpertises.includes(val);
        },
        then: Yup.array().of(
          Yup.object().test("additional-field-check", "Required", (v) => {
            return !(v.required && !(v.value as string)?.length);
          })
        ),
      })
      .of(
        Yup.object().test(
          "additional-field-length",
          "Maximum 250 characters allowed",
          (v) => {
            return !(
              (v.value as string)?.length && (v.value as string)?.length > 250
            );
          }
        )
      ),
    departmentId: Yup.number()
      .nullable()
      .when("companyId", {
        is: (companyId: number) => {
          const selectedCompany = companies.find((c) => c.id === companyId);
          return selectedCompany?.requireDepartment;
        },
        then: Yup.number().required("Department is required when selected company requires it"),
      }),
  });

  useEffect(() => {
    const init = async () => {
      const spoken = await LanguagesService.getSpokenLanguages();
      const sign = await LanguagesService.getSignLanguages();
      const fetchedAreas = await ExpertiseService.getExpertiseAreas();
      const { companies: fetchedCompanies } = await CompanyService.getCompanies(
        {
          current: 1,
          pageSize: 0,
        }
      );

      setSpokenLanguages(spoken);
      setSignLanguages(sign);
      setExpertiseAreas(fetchedAreas);
      const medicineExpertiseId = fetchedAreas.find((e) => e.title_en === 'Medicine and health care')?.id ?? 0;
      const psychiatrieExpertiseId = fetchedAreas.find((e) => e.title_en === 'Psychiatry / psychotherapy')?.id ?? 0;
      setAdditionalFieldsExpertises([medicineExpertiseId, psychiatrieExpertiseId])
      setCompanies(fetchedCompanies);

      if (!copySessionId) return;

      try {
        const sessionData = await VideosessionService.getVideosession(copySessionId);

        const translateFrom = spoken.find(l => l.id === sessionData.translateFrom)?.code || "en";
        const translateTo = spoken.find(l => l.id === sessionData.translateTo)?.code;
        const translateToSign = sign.find(l => l.id === sessionData.translateToSign)?.code;
        setInitialValues({
          title: sessionData.title || "",
          description: sessionData.description || "",
          type: sessionData.translateToSign ? 'sign' : "spoken",
          startTime: sessionData.startTime ? new Date(sessionData.startTime) : new Date(Date.now() + 60 * 60 * 1000),
          timeRange: sessionData.timeRange || "m30",
          gender: sessionData.gender || "none",
          workWithPreviousInterpreters: !!sessionData.selected?.length || false,
          dialect: sessionData.dialect || undefined,
          expertiseArea: sessionData.expertiseArea?.id || 1,
          additionalFields: sessionData.additionalFields || initialAdditionalFields,
          isFree: sessionData.history?.allowForFree || false,
          companyId: sessionData.company?.id || undefined,
          userId: sessionData.creator?.id || undefined,
          costCenter: sessionData.costCenter || undefined,
          departmentId: sessionData.department?.id || undefined,
          translateFrom,
          translateTo,
          translateToSign,
        });
        if (sessionData.selected?.length) {
          const fetchedInterpreters =
            await InterpreterService.getInterpretersForSession(
              translateFrom,
              sessionData.expertiseArea?.id || 1,
              sessionData.gender,
              translateTo,
              translateToSign
            );
          setAvailableInterpreters(fetchedInterpreters);
          setInviteInterpreters(true);
          setSelectedInterpreters(sessionData.selected.map(i => i.id))
          setAllowToSearchNew(!!sessionData.searchAll)
        }
        if (sessionData.company?.id) {
          setSelectedCompanyId(sessionData.company.id);
        }
        navigate("/admin/videosessions/new", { replace: true });
      } catch (error) {
        message.error("Failed to fetch session data for copying.");
      }
    };

    init();
  }, [copySessionId, navigate]);


  useEffect(() => {
    if (!selectedCompanyId) return;
    const updateCreators = async () => {
      const fetchedCreators = await CompanyService.getCompanyUsers(
        selectedCompanyId
      );
      setCreators(fetchedCreators);
    };
    updateCreators();
  }, [selectedCompanyId]);

  const handleInviteInterpreters = async (
    translateFrom: string,
    expertiseArea: number,
    type: "sign" | "spoken",
    gender: "male" | "female" | "none",
    translateTo?: string,
    translateToSign?: string
  ) => {
    if (inviteInterpreters) {
      setInviteInterpreters(false);
      setSelectedInterpreters([]);
      return;
    }
    const fetchedInterpreters =
      await InterpreterService.getInterpretersForSession(
        translateFrom,
        expertiseArea,
        gender === "none" ? undefined : gender,
        type === "spoken" ? translateTo : undefined,
        type === "sign" ? translateToSign : undefined
      );
    setAvailableInterpreters(fetchedInterpreters);
    setInviteInterpreters(true);
  };

  const sortLanguages = (a: { title_en: string }, b: { title_en: string }) => {
    if (a.title_en < b.title_en) return -1;
    if (a.title_en > b.title_en) return 1;
    return 0;
  };

  const getLanguageOptions = (type: string | undefined) => {
    if (type === "spoken")
      return spokenLanguages.sort(sortLanguages).map((lang) => (
        <Select.Option key={lang.code} value={lang.code} label={lang.title_en}>
          {lang.title_en}
        </Select.Option>
      ));
    return signLanguages.sort(sortLanguages).map((lang) => (
      <Select.Option key={lang.code} value={lang.code} label={lang.title_en}>
        {lang.title_en}
      </Select.Option>
    ));
  };

  const handleInterpreterSelect = (interpreterId: number) => {
    const selectedInterpretersToSet = [...selectedInterpreters, interpreterId];
    setSelectedInterpreters(selectedInterpretersToSet);
  };

  const handleInterpreterDeselect = (interpreterId: number) => {
    const selectedInterpretersToSet = selectedInterpreters.filter(
      (i) => i !== interpreterId
    );
    setSelectedInterpreters(selectedInterpretersToSet);
  };

  const handleSubmit = async (values: ICreateVideosessionFormData) => {
    const selectedDepartment = selectedCompany?.requireDepartment && values.departmentId && selectedCompany.companyDepartments.find(d => d.id === values.departmentId)
    const costCenter = selectedDepartment ? selectedDepartment.costCenter || selectedDepartment.title_de : values.costCenter
    setValuesToSubmit({
      ...values,
      gender: values.gender === "none" ? undefined : values.gender,
      userId: values.userId ?? 0,
      additionalFields:
        additionalFieldsExpertises.includes(values.expertiseArea) &&
          values.additionalFields?.length
          ? values.additionalFields
            .filter((f) => !!f.value?.length)
            .map((field) => ({
              name: field.name,
              value: field.value,
              title: field.title,
            }))
          : undefined,
      interpretersToInvite: selectedInterpreters,
      costCenter
    });
    const codeSecret = await AdminService.verifyMe();
    setSecret(codeSecret);
    setModalVisible(true);
  };

  const confirmCode = async () => {
    if (!valuesToSubmit || !code || !secret) {
      console.log(valuesToSubmit, code, secret);
      message.error("Something went wrong");
      return;
    }
    try {
      const createdSession = await VideosessionService.createVideosession({
        ...valuesToSubmit,
        code,
        secret,
      });
      message.success(
        `Session "${createdSession.title}" is created successfully`
      );
      setModalVisible(false);
    } catch (err) {
      message.error("Something went wrong");
    }
  };

  const selectedCompany = useMemo(() => {
    return companies.find((c) => c.id === selectedCompanyId)
  }, [selectedCompanyId])

  return (
    <div>
      <Title>New session</Title>
      <Formik
        initialValues={initialValues}
        validationSchema={sessionSchema}
        enableReinitialize
        onSubmit={async (
          values,
          { setSubmitting, setFieldError, resetForm }
        ) => {
          setSubmitting(true);
          try {
            await handleSubmit(values);
            resetForm();
          } catch (err: any) {
            if (err.response?.data?.statusCode === 400) {
              const [field, messageText] = err.response.data?.message[0]
                .match(/^(\S+)\s(.*)/)
                .slice(1);

              setFieldError(field, messageText);
            } else {
              message.error("Something went wrong");
            }
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ values, isSubmitting, submitForm, errors }) => {
          if (
            ((values.type === "spoken" && !values.translateTo) ||
              (values.type === "sign" && !values.translateToSign) ||
              !values.expertiseArea) &&
            inviteInterpreters
          ) {
            setInviteInterpreters(false);
          }
          return (
            <Form>
              <Row gutter={16}>
                <Col span={12} className="wrap">
                  <div className="languages">
                    <Space direction="vertical" style={{ alignItems: "start" }}>
                      <Text strong>Translate from: *</Text>
                      <Select
                        name="translateFrom"
                        showSearch
                        style={{ width: 300 }}
                        disabled={inviteInterpreters}
                        filterOption={(input, option) =>
                          !!(option?.label as string)?.toLowerCase().includes(input.toLowerCase())
                        }
                      >
                        {getLanguageOptions("spoken")}
                      </Select>
                      <ErrorMessage
                        name="translateFrom"
                        component="p"
                        className="error"
                      />
                    </Space>
                  </div>
                  <div className="inputGroup">
                    <Text strong>Type: *</Text>
                    <Select
                      name="type"
                      style={{ width: 300 }}
                      disabled={inviteInterpreters}
                    >
                      <Select.Option value="sign">Sign</Select.Option>
                      <Select.Option value="spoken">Spoken</Select.Option>
                    </Select>
                    <ErrorMessage name="type" component="p" className="error" />
                  </div>
                  <div className="languages">
                    <Space direction="vertical" style={{ alignItems: "start" }}>
                      <Text strong>Translate to: *</Text>
                      <Select
                        name={
                          values.type === "spoken"
                            ? "translateTo"
                            : "translateToSign"
                        }
                        showSearch
                        style={{ width: 300 }}
                        disabled={inviteInterpreters}
                        filterOption={(input, option) =>
                          !!(option?.label as string)?.toLowerCase().includes(input.toLowerCase())
                        }
                      >
                        {getLanguageOptions(values.type)}
                      </Select>
                      <ErrorMessage
                        name={
                          values.type === "spoken"
                            ? "translateTo"
                            : "translateToSign"
                        }
                        component="p"
                        className="error"
                      />
                    </Space>
                  </div>
                  {values.type === "spoken" && (
                    <div className="languages">
                      <Space
                        direction="vertical"
                        style={{ alignItems: "start" }}
                      >
                        <Text strong>Dialect:</Text>
                        <Select
                          name={"dialect"}
                          showSearch
                          style={{ width: 300 }}
                          disabled={inviteInterpreters}
                          filterOption={(input, option) =>
                            !!(option?.label as string)
                              ?.toLowerCase()
                              .startsWith(input.toLowerCase())
                          }
                        >
                          {dialectCountries.sort(sortLanguages).map((lang) => (
                            <Select.Option
                              key={lang.code}
                              label={lang.title_en}
                              value={lang.code}
                            >
                              {lang.title_en}
                            </Select.Option>
                          ))}
                        </Select>
                        <ErrorMessage
                          name={
                            values.type === "spoken"
                              ? "translateTo"
                              : "translateToSign"
                          }
                          component="p"
                          className="error"
                        />
                      </Space>
                    </div>
                  )}
                  <div className="inputGroup">
                    <Text strong>Gender: *</Text>
                    <Select
                      name="gender"
                      style={{ width: 120 }}
                      disabled={inviteInterpreters}
                    >
                      <Select.Option value="male">Male</Select.Option>
                      <Select.Option value="female">Female</Select.Option>
                      <Select.Option value="none">Any</Select.Option>
                    </Select>
                    <ErrorMessage
                      name="bornAt"
                      component="p"
                      className="error"
                    />
                  </div>

                  <div className="expertiseAreas">
                    <Space direction="vertical" style={{ alignItems: "start" }}>
                      <Text strong>Expertise Area: *</Text>
                      <Select
                        name="expertiseArea"
                        showSearch
                        style={{ width: 300 }}
                        disabled={inviteInterpreters}
                      >
                        {expertiseAreas.map((ea) => (
                          <Select.Option key={ea.id} value={ea.id}>
                            {ea.title_en}
                          </Select.Option>
                        ))}
                      </Select>
                      <ErrorMessage
                        name="expertiseArea"
                        component="p"
                        className="error"
                      />
                    </Space>
                  </div>

                  <div>
                    <AntCheckbox
                      checked={inviteInterpreters}
                      onChange={() =>
                        handleInviteInterpreters(
                          values.translateFrom,
                          values.expertiseArea,
                          values.type,
                          values.gender as "none" | "male" | "female",
                          values.translateTo,
                          values.translateToSign
                        )
                      }
                      disabled={
                        (values.type === "spoken" && !values.translateTo) ||
                        (values.type === "sign" && !values.translateToSign) ||
                        !values.expertiseArea
                      }
                    >
                      Invite interpreters
                    </AntCheckbox>
                  </div>
                  {inviteInterpreters && (
                    <div>
                      <Space
                        direction="vertical"
                        style={{ alignItems: "start" }}
                      >
                        <Text strong>Interpreters to invite:</Text>
                        <AntSelect
                          showSearch
                          style={{ width: 400 }}
                          mode="multiple"
                          filterOption={(input, option) =>
                            !!(option?.label as string)
                              ?.toLowerCase()
                              .startsWith(input.toLowerCase())
                          }
                          onSelect={handleInterpreterSelect}
                          onDeselect={handleInterpreterDeselect}
                          value={selectedInterpreters as any}
                        >
                          {availableInterpreters.map((interpreter) => (
                            <AntSelect.Option
                              value={interpreter.id}
                              label={interpreter.email}
                            >
                              "{interpreter.email}" {interpreter.firstName}{" "}
                              {interpreter.lastName}
                            </AntSelect.Option>
                          ))}
                        </AntSelect>
                        <AntCheckbox
                          checked={allowToSearchNew}
                          onChange={() =>
                            setAllowToSearchNew(!allowToSearchNew)
                          }
                        >
                          Invite others if not accepted in 30 mins
                        </AntCheckbox>
                      </Space>
                    </div>
                  )}
                  <div>
                    <Space direction="vertical" style={{ alignItems: "start" }}>
                      <Text strong>Companies: *</Text>
                      <Select
                        name="companyId"
                        showSearch
                        style={{ width: 300 }}
                        filterOption={(input, option) =>
                          !!(option?.label as string)
                            ?.toLowerCase()
                            .startsWith(input.toLowerCase())
                        }
                        onSelect={(v: number) => setSelectedCompanyId(v)}
                      >
                        {companies.map((company) => (
                          <Select.Option
                            value={company.id}
                            label={company.name}
                          >
                            "{company.name}" ID{company.id}
                          </Select.Option>
                        ))}
                      </Select>
                      <ErrorMessage
                        name="companyId"
                        component="p"
                        className="error"
                      />
                    </Space>
                  </div>
                  {values.companyId && (
                    <div>
                      <Space
                        direction="vertical"
                        style={{ alignItems: "start" }}
                      >
                        <Text strong>Creator: *</Text>
                        <Select
                          name="userId"
                          showSearch
                          style={{ width: 300 }}
                          filterOption={(input, option) =>
                            !!(option?.label as string)
                              ?.toLowerCase()
                              .startsWith(input.toLowerCase())
                          }
                        >
                          {creators.map((user) => (
                            <Select.Option value={user.id} label={user.email}>
                              "{user.email}" {user.firstName} {user.lastName}
                            </Select.Option>
                          ))}
                        </Select>
                        <ErrorMessage
                          name="userId"
                          component="p"
                          className="error"
                        />
                      </Space>
                    </div>
                  )}
                </Col>
                <Col span={12} className="wrap">
                  <div className="inputGroup">
                    <Text strong>Start date: *</Text>
                    <DatePicker
                      name="startTime"
                      format="DD/MM/YYYY HH:mm"
                      placeholder="dd/mm/yyyy hh:mm"
                      suffixIcon=""
                      onChange={(e) => console.log(e)}
                      defaultPickerValue={moment().subtract(18, "year")}
                      showTime={{
                        defaultValue: moment("00:00:00", "HH:mm:ss"),
                      }}
                      disabledDate={(current) =>
                        current &&
                        current < moment().subtract(1, "day").endOf("day")
                      }
                    />
                    <ErrorMessage
                      name="startTime"
                      component="p"
                      className="error"
                    />
                  </div>
                  <div className="inputGroup">
                    <Text strong>Time range: *</Text>
                    <Select name="timeRange" style={{ width: 300 }}>
                      <Select.Option value="m30">Up to 30 min</Select.Option>
                      <Select.Option value="h1">30 - 60 min</Select.Option>
                      <Select.Option value="h2">1h - 2h</Select.Option>
                      <Select.Option value="more">2h +</Select.Option>
                    </Select>
                    <ErrorMessage
                      name="timeRange"
                      component="p"
                      className="error"
                    />
                  </div>
                  <div className="inputGroup">
                    <Text strong>Title: *</Text>
                    <Input name="title" />
                    <ErrorMessage
                      name="title"
                      component="p"
                      className="error"
                    />
                  </div>
                  <div className="inputGroup">
                    <Text strong>Description:</Text>
                    <Input.TextArea
                      name="description"
                      rows={5}
                      style={{ resize: "none" }}
                    />
                    <ErrorMessage
                      name="description"
                      component="p"
                      className="error"
                    />
                  </div>
                  <div className="inputGroup">
                    <Checkbox name="isFree">Make session free</Checkbox>
                  </div>
                  {
                    selectedCompany?.requireDepartment ?
                      <div className="inputGroup">
                        <Text strong>Department (Cost center): *</Text>
                        <Select
                          name="departmentId"
                          showSearch
                          style={{ width: 300 }}
                          filterOption={(input, option) =>
                            !!(option?.label as string)
                              ?.toLowerCase()
                              .startsWith(input.toLowerCase())
                          }
                        >
                          {selectedCompany.companyDepartments.filter(d => !!d.parentDepartment).map((department) => (
                            <Select.Option
                              key={department.id}
                              value={department.id}
                              label={department.title_de}
                            >
                              {department.title_de}({department.parentDepartment?.title_de})
                            </Select.Option>
                          ))}
                        </Select>
                        <ErrorMessage
                          name="departmentId"
                          component="p"
                          className="error"
                        />
                      </div>
                      :
                      <div className="inputGroup">
                        <Text strong>Cost center:</Text>
                        <Input name="costCenter" />
                        <ErrorMessage
                          name="costCenter"
                          component="p"
                          className="error"
                        />
                      </div>
                  }
                  {additionalFieldsExpertises.includes(values.expertiseArea) && (
                    <>
                      <div className="inputGroup">
                        <Text strong>Additional fields</Text>
                      </div>
                      <FieldArray
                        name="additionalFields"
                        render={() => (
                          <>
                            {additionalFields.map((addField, i) => (
                              <div className="inputGroup">
                                <AdditionalField
                                  index={i}
                                  title={addField.title}
                                  options={addField.options}
                                  placeholder={addField.placeholder}
                                  language={"en"}
                                  required={addField.required}
                                />
                                <ErrorMessage
                                  name={addField.name}
                                  component="p"
                                  className="error"
                                />
                              </div>
                            ))}
                          </>
                        )}
                      />
                    </>
                  )}
                </Col>
              </Row>
              <Button
                className="saveBtn"
                onClick={submitForm}
                disabled={isSubmitting}
                loading={isSubmitting}
                type="primary"
              >
                Create
              </Button>
            </Form>
          );
        }}
      </Formik>
      <Modal
        title="Enter code"
        visible={modalVisible}
        onOk={confirmCode}
        onCancel={() => setModalVisible(false)}
      >
        <AntInput value={code} onChange={(v) => setCode(v.target.value)} />
      </Modal>
    </div>
  );
};

export default NewSession;
